export default function(bikeId, lat, lng, seqNo, time, positioningTime, speed, heading, temperature, batteryVoltage, rxTechnology, batteryLow, maxSpeedExceeded, areasIn) {
    this.bikeId = bikeId;
    this.lat = lat;
    this.lng = lng;
    this.seqNo = seqNo;
    this.speed = speed;
    this.heading = heading;
    this.temperature = temperature;
    this.batteryVoltage = batteryVoltage;
    this.rxTechnology = rxTechnology;
    this.batteryLow = batteryLow && lng < 20;
    this.maxSpeedExceeded = maxSpeedExceeded && lng < 20;
    this.areasIn = areasIn;

    // If a string was passed, parse it to a Date object
    if (typeof time === "string") {
        this.time = new Date(time);
        // Otherwise we assume a Date was passed
    } else {
        this.time = time;
    }

    // If a string was passed, parse it to a Date object
    if (typeof positioningTime === "string") {
        this.positioningTime = new Date(positioningTime);
        // Otherwise we assume a Date was passed
    } else {
        this.positioningTime = positioningTime;
    }

    this.outsideAllowedArea = lng ? !areasIn.includes("allowed") && lng < 20 : null;
    this.stationaryOutsideStation = lng ? speed === 0 && areasIn.filter(area => { return area.startsWith("station-"); }).length === 0 && lng < 20 : null;
  }