import React from "react";

export default function GoogleMap({id, className, apiKey, options, since, until, onMapLoad, onMapUnload = () => {}}) {
    let map = null;

    React.useEffect(() => {
        if(!window.google) {
            const mapScriptElem = document.createElement("script");
            mapScriptElem.type = "text/javascript";
            mapScriptElem.src = "https://maps.google.com/maps/api/js?key=" + apiKey;
            const firstScriptElem = document.getElementsByTagName("script")[0];
            firstScriptElem.parentNode.insertBefore(mapScriptElem, firstScriptElem);
            mapScriptElem.addEventListener("load", e => {
                createMap();
                onMapLoad(map);
            });
        } else {
            createMap();
            onMapLoad(map);
        }

        return onMapUnload;
    }, [since, until]); // eslint-disable-line

    function createMap() {
        map = new window.google.maps.Map(document.getElementById(id), options);
    }

    /*function createMarker(lat, long, zIndex, icon) {
        return new Marker({
            position: new LatLng(lat, long),
            draggable: false,
            zIndex: zIndex,
            icon: icon
        });
    }

    function addMarker(lat, long, icon, zIndex, onMouseOver, onClick) {
        const marker = new Marker({
            position: new LatLng(lat, long),
            draggable: false,
            zIndex: zIndex,
            icon: icon
        });

        event.addListener(marker, "mouseover", function() {
            //clickInfoWindow.close();
            //clickInfoWindow.setContent(descriptions[id]);

            //clickInfoWindow.open(map, this);
        });
    }*/

    return <div id={id} className={className} />;
}