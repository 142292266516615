import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {ThemeProvider} from '@material-ui/styles';
import App from './App';
import Devices from './Devices';
import theme from './theme';
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function stripTrailingSlash(str) {
    if (str.substr(-1) === '/') {
        return str.substr(0, str.length - 1);
    }
    return str;
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

fetch("/config.json")
    .then(config => config.json())
    .then(config => {
            function authorize() {
                const authorizeUrl = "https://authorization.netemera.com/oauth2/authorize?response_type=token&client_id=tracking-ui&redirect_uri=" + stripTrailingSlash(encodeURIComponent(window.location.href)) + "&audience=https://tracking.netemera.com/api/v1";
                window.location.replace(authorizeUrl);
            }

            localStorage.removeItem("access-token");
            const code = getUrlParameter("access_token");
            if (code !== "") {
                localStorage.setItem("access-token", code);
                window.history.replaceState && window.history.replaceState(
                    null, document.title, window.location.pathname + window.location.search.replace(/[?&]code=[^&]+/, '').replace(/^&/, '?') + window.location.hash
                );
                window.setTimeout(authorize, 1000 * 60 * 60 * 24);
            }

            if (localStorage.getItem("access-token") === null || localStorage.getItem("access-token") === "") {
                authorize();
            } else {
                const organizationId = parseJwt(localStorage.getItem("access-token")).organization.split(" ")[0];

                ReactDOM.render(
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>
                        <BrowserRouter>
                            <Switch>
                                <Route path="/devices">
                                    <Devices config={config}/>
                                </Route>
                                <Route path="/">
                                    <App config={config}/>
                                </Route>
                            </Switch>
                        </BrowserRouter>
                    </ThemeProvider>,
                    document.querySelector('#root')
                )
            }
        }
    );
