import Bike from "./Bike"
import BikePosition from "./BikePosition"
import {EventSourcePolyfill} from "event-source-polyfill";
import {fetch} from 'whatwg-fetch'

export default function (config) {
    function retrieveBikes(groupId, callback) {
        fetch(config.baseUri + "/groups/" + groupId + "/trackers", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            const bikes = json.data.map(function (e) {
                return new Bike(e.id, e.id, e.attributes.name, e.attributes.organizationId, e.attributes.groupId);
            });
            callback(bikes);
        });
    }

    function updateBike(groupId, bike, callback) {
        fetch(config.baseUri + "/groups/" + groupId + "/trackers/" + bike.id, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            },
            body: JSON.stringify({data: {type: "tracker", id: bike.id, attributes: {id: bike.id, name: bike.name, type: {"NETEMERA_TRACKER_LTE":{}}}}})
        }).then(function(response) {
            return callback(bike);
        });
    }

    function retrieveCurrentBikePositions(groupId, callback) {
        fetch(config.baseUri + "/states/groups/" + groupId + "?filter[tail]=1", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            const now = Date.now();
            const entries = json.data.filter(function (entry) {
                return entry.attributes.positioning != null && ((window.admin || !window.admin && (Date.parse(entry.attributes.time) > (now - 864000000))) && entry.attributes.trackerId !== "iccid-8948031824602134973");
            }).map(function (entry) {
                    return new BikePosition(entry.attributes.trackerId, entry.attributes.positioning.position.lat,
                        entry.attributes.positioning.position.long, entry.attributes.seqNo,
                        new Date(entry.attributes.time), entry.attributes.positioning.time,
                        entry.attributes.positioning.speed, entry.attributes.positioning.heading,
                        entry.attributes.temperature, entry.attributes.batteryVoltage,
                        entry.attributes.rxMetadata.technology, entry.attributes.batteryLow, entry.attributes.positioning.maxSpeedExceeded, entry.attributes.positioning.areasIn);
            });
            callback(entries);
        });
    }

    function retrieveCurrentBikePositions2(groupId, callback) {
        fetch(config.baseUri + "/states/groups/" + groupId + "?filter[tail]=1", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            const now = Date.now();
            const entries = json.data.map(function (entry) {
                return new BikePosition(entry.attributes.trackerId, null,
                    null, entry.attributes.seqNo,
                    new Date(entry.attributes.time), null,
                    null, null,
                    entry.attributes.temperature, entry.attributes.batteryVoltage,
                    entry.attributes.rxMetadata.technology, entry.attributes.batteryLow, null, null);
            });
            callback(entries);
        });
    }

    function retrieveCurrentBikePositionsUntil(groupId, until, callback) {
        fetch(config.baseUri + "/states/groups/" + groupId + "?filter[tail]=1&filter[until]=" + until.toISOString(), {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            const entries = json.data.filter(function (entry) {
                return entry.attributes.positioning != null;
            }).map(function (entry) {
                return new BikePosition(entry.attributes.trackerId, entry.attributes.positioning.position.lat,
                    entry.attributes.positioning.position.long, entry.attributes.seqNo,
                    new Date(entry.attributes.time), entry.attributes.positioning.time,
                    entry.attributes.positioning.speed, entry.attributes.positioning.heading,
                    entry.attributes.temperature, entry.attributes.batteryVoltage,
                    entry.attributes.rxMetadata.technology, entry.attributes.batteryLow, entry.attributes.positioning.maxSpeedExceeded, entry.attributes.positioning.areasIn);
            });
            callback(entries);
        });
    }

    function retrieveCurrentStates(groupId, callback) {
        fetch(config.baseUri + "/states/groups/" + groupId + "?filter[tail]=1", {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            const entries = json.data.map(function (entry) {
                return entry.attributes;
            });
            callback(entries);
        });
    }

    function retrieveBikePositions(bikeId, fromTime, toTime, callback) {
        fetch(config.baseUri + "/states/trackers/" + bikeId + "?filter[since]=" + fromTime.toISOString() + "&filter[until]=" + toTime.toISOString(), {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("access-token")
            }
        }).then(function(response) {
            return response.json();
        }).then(function(json) {
            const entries = json.data.filter(function (entry) {
                return entry.attributes.positioning != null;
            }).map(function (entry) {
                    return new BikePosition(entry.attributes.trackerId, entry.attributes.positioning.position.lat,
                        entry.attributes.positioning.position.long, entry.attributes.seqNo,
                        new Date(entry.attributes.time), entry.attributes.positioning.time,
                        entry.attributes.positioning.speed, entry.attributes.positioning.heading,
                        entry.attributes.temperature, entry.attributes.batteryVoltage,
                        entry.attributes.rxMetadata.technology, entry.attributes.batteryLow,
                        entry.attributes.positioning.maxSpeedExceeded, entry.attributes.positioning.areasIn);
            });
            callback(entries);
        });
    }

    function onBikePositionChange(groupId, callback) {
        const eventSource = new EventSourcePolyfill(config.baseUri + "/states/groups/" + groupId, {
            headers: {
                "Accept": "text/event-stream",
                'Authorization': 'Bearer ' + localStorage.getItem("access-token")
            }});

        eventSource.onmessage = function (result) {
            if (result.data !== "") {
                const data = JSON.parse(result.data);
                if (data.positioning != null) {
                    callback(new BikePosition(data.trackerId, data.positioning.position.lat,
                        data.positioning.position.long, data.seqNo, new Date(data.time), data.positioning.time,
                        data.positioning.speed, data.positioning.heading, data.temperature, data.batteryVoltage,
                        data.rxMetadata.technology, data.batteryLow, data.positioning.maxSpeedExceeded,
                        data.positioning.areasIn));
                }
            }
        };
    }

    return {
        onBikePositionChange: onBikePositionChange,
        retrieveBikes: retrieveBikes,
        updateBike: updateBike,
        retrieveCurrentBikePositions: retrieveCurrentBikePositions,
        retrieveCurrentBikePositions2: retrieveCurrentBikePositions2,
        retrieveCurrentBikePositionsUntil: retrieveCurrentBikePositionsUntil,
        retrieveCurrentStates: retrieveCurrentStates,
        retrieveBikePositions: retrieveBikePositions
    };
};