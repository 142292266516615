export default function (dataSource, map, moment, since, until) {
    // Hash map for storing bikes
    const bikes = {};
    const bikesByName = {};
    var trackingId = null;
    var selectedId = null;
    var currentPositionMarkerIds = [];
    var lastPathPositionMarkerIds = [];
    var bikePositions = {};

    var last = "1w";

    function highlightBike(bikeId) {
      //map.updateMarkerIcon(bikeId, MarkerStyles.currentLiveHighlighted.icon);
      const marker = map.getMarkerById(bikeId);
      removeLastPath();
      map.flyToMarker(marker, 18);
    }

    function unHighlightBike(bikeId) {
       // map.updateMarkerIcon(bikeId, MarkerStyles.currentLive.icon);
        removeLastPath();
        map.recenter();
    }

    // Styles used for map markers (points, circles)
    const MarkerStyles = {
        currentLive: {
            icon: {
                url: 'current-position.png',
                size: new window.google.maps.Size(15, 15),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(7.5, 7.5)
            }
            /*icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: "blue",//"#00FF00",//"red", //"#e56a5d",
                fillOpacity: 1,
                scale: 6,
                strokeColor: "#ffffff", //"red",
                strokeWeight: 2,
                strokeOpacity: 1
            }*/
        },
        currentLiveAlert: {
            icon: {
                url: 'current-position-alert.png',
                size: new window.google.maps.Size(15, 15),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(7.5, 7.5)
            }
            /*icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: "blue",//"#00FF00",//"red", //"#e56a5d",
                fillOpacity: 1,
                scale: 6,
                strokeColor: "red", //"red",
                strokeWeight: 2,
                strokeOpacity: 1
            }*/
        },
        pastLive: {
            icon: {
                url: 'history-position.png',
                size: new window.google.maps.Size(4, 4),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(2, 2)
            }
            /*icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: "blue",//"#00FF00",//"red",//fillColor: "#53585C", //"#A8A8A8",
                fillOpacity: 1,
                scale: 4,
                strokeColor: "#ffffff", //strokeColor: "#53585C",
                strokeWeight: 0,
                strokeOpacity: 1
            }*/
        }
    };

    const LineStyle = {
        strokeColor: "blue",//"#00FF00",//strokeColor: "#53585C",
        strokeWeight: 2,
        strokeOpacity: 1,
    };

    var currentGroupId = "";

    /**
     * Initialize bike manager with the following steps:
     *   - retrieve bike metadata
     *   - retrieve last position for every bike
     *   - show home screen with all bikes visible
     *   - set up live update of positions on home and last path screens
     * @param {Object} callback - to be called when bike metadata and positions have been loaded
     */
    function init(groupId, callback) {
        currentGroupId = groupId;
        dataSource.retrieveBikes(currentGroupId, function (bs) {

            bs.forEach(function (bike) {
                bikes[bike.id] = bike;
                bikesByName[bike.name] = bike.id;
            });

            dataSource.retrieveCurrentBikePositionsUntil(currentGroupId, until ? until : new Date(),function (bikePositions) {

                bikePositions.filter(pos => pos.time.getTime() > since.getTime()).forEach(function (bikePosition) { //TODO remove filter
                //bikePositions.forEach(function (bikePosition) { //TODO remove filter
                    const bike = bikes[bikePosition.bikeId];
                    bike.currentPosition = bikePosition;
                    bikePositions[bike.id] = [];
                    addCurrentPosition(bike);
                });

                // We are set, apply the callback now
                callback();

                outsideAllowedAreaCallback(calculateOutsideAllowedArea());
                stationaryOutsideStationCallback(calculateStationaryOutsideStation());
                batteryLowCallback(calculateBatteryLow());
                maxSpeedExceededCallback(calculateMaxSpeedExceeded());

                // Register bike position change handler (server stream)

                if(!until) {
                    dataSource.onBikePositionChange(currentGroupId, function (bikePosition) {
                        const bike = bikes[bikePosition.bikeId];
                        if (bike.currentPosition === null) {
                            bike.currentPosition = bikePosition;
                            addCurrentPosition(bike);
                        } else {
                            bike.currentPosition = bikePosition;
                            updateCurrentPosition(bike);
                        }

                        if (bike.id === trackingId) {
                            bikePositions[bike.id].unshift(bikePosition); // TODO remove
                            updateLastPath(bike);
                        }

                        outsideAllowedAreaCallback(calculateOutsideAllowedArea());
                        stationaryOutsideStationCallback(calculateStationaryOutsideStation());
                        batteryLowCallback(calculateBatteryLow());
                    });
                }
            });
        });
    }

    function deInit() {

    }

    function updateBikeName(id, name) {
        const bike = bikes[id];
        bike.name = name;
        dataSource.updateBike(currentGroupId, bike, function (bike) {
            updateCurrentPosition(bike);
        });
    }

    function updateCurrentPosition(bike) {
        map.updateMarker(bike.id, bike.currentPosition.lat, bike.currentPosition.lng, createDescription(bike, bike.currentPosition));
        setIcon(bike);
    }

    function addCurrentPosition(bike) {
            const markerId = bike.id;
            const markerStyle = MarkerStyles.currentLive;

            map.addMarker(bike.id,
                bike.currentPosition.lat,
                bike.currentPosition.lng,
                bike.name,
                createDescription(bike, bike.currentPosition),
                markerStyle,
                999,
                function () {
                    removeLastPath();
                    addLastPath(bike.id, last);
                });
            setIcon(bike);
            currentPositionMarkerIds.push(markerId);
    }

    function setIcon(bike) {
        var icon = MarkerStyles.currentLive;
        if(bike.currentPosition.outsideAllowedArea) {
            icon = MarkerStyles.currentLiveAlert;
        } else if(bike.currentPosition.stationaryOutsideStation) {
            icon = MarkerStyles.currentLiveAlert;
        } else if(bike.currentPosition.batteryLow) {
            icon = MarkerStyles.currentLiveAlert;
        } else if(bike.currentPosition.maxSpeedExceeded) {
            icon = MarkerStyles.currentLiveAlert;
        }
        map.updateMarkerIcon(bike.id, icon.icon);
    }

    function addHistoryPosition(bike, bikePosition) {
        const markerId = bike.id + "_" + bikePosition.time.getTime();
        lastPathPositionMarkerIds.push(markerId);
        const markerStyle = MarkerStyles.pastLive;
        map.addMarker(markerId,
            bikePosition.lat, bikePosition.lng, bike.name,
            createDescription(bike, bikePosition), markerStyle, 998, function () {
            });
    }

    function addLastPath(bikeId, duration) {
        if (!bikes[bikeId]) {
            throw new Error("Trying to show path for a non-existent bike: " + bikeId);
        }

        trackingId = bikeId;
        selectedId = bikeId;

        const bike = bikes[bikeId];

        const fromTime = since ? since : new Date();
        var toTime = until;

        if (bike.currentPosition !== null) toTime = bike.currentPosition.time; else toTime = new Date();

        /*switch (duration) {
            case '3h':
                fromTime.setHours(fromTime.getHours() - 3);
                break;
            case '1d':
                fromTime.setHours(fromTime.getHours() - 24);
                break;
            case '3d':
                fromTime.setHours(fromTime.getHours() - 3 * 24);
                break;
            case '1w':
                fromTime.setHours(fromTime.getHours() - 7 * 24);
                break;
            case '4w':
                fromTime.setHours(fromTime.getHours() - 7 * 4 * 24);
                break;
            default:
                throw new Error("Unsupported duration: " + duration + ". Supported durations: 3h, 1d, 3d, 1w, 4w");
        }*/

        dataSource.retrieveBikePositions(bike.id, fromTime, toTime, function (bikePositions) {
            setPath(bike, bikePositions, bike.currentPosition);
        });
    }

    function setPath(bike, bikePositions, currentPosition) {
        //currentPositionMarkerIds.forEach(id => {
            //if(id !== bike.id) {
                //map.updateMarkerIcon(id, MarkerStyles.currentLiveHidden.icon);
            //}
        //});

        bikePositions.forEach(bikePosition => {
            addHistoryPosition(bike, bikePosition);
        });

        const coordinates = bikePositions.map(function (position) {
            return {lat: position.lat, lng: position.lng}
        });

        if (currentPosition !== null) {
            coordinates.push({lat: currentPosition.lat, lng: currentPosition.lng});
        }

        map.addLine(coordinates, LineStyle.strokeWeight, LineStyle.strokeColor, LineStyle.opacity);
    }

    const timeLimit = new Date(1571844757 * 1000);

    function createDescription(bike, bikePosition) {
        var radioTech = null;

        switch(bikePosition.rxTechnology) {
            case "EGPRS":
                radioTech = "EGPRS";
                break;
            case "LTE_CAT_M1":
                radioTech = "LTE Cat. M1";
                break;
            case "LTE_CAT_NB1":
                radioTech = "LTE Cat. NB1";
                break;
            case "LORA":
                radioTech = "LoRa";
                break;
            default:
            // code block
        }
        return "<input type=\"text\"" + (window.admin ? "" : " disabled") + " onchange=\"window.updateTrackerName('" + bike.id + "', this.value)\" style=\"font-family: 'Roboto'; font-size: 13px; font-weight: bold; border: 0; width: 100%;\" value=\"" + bike.name + "\" /><br \>ID: " + bike.id + "<br \>Time: " + moment(bikePosition.time).format("YYYY-MM-DD HH:mm:ss") + "<br \>Position (lat, long): " + bikePosition.lat + ", " + bikePosition.lng + "<br \>Positioning time: " + moment(bikePosition.positioningTime).format("YYYY-MM-DD HH:mm:ss") + ((bikePosition.speed != null) ? "<br \>Speed: " + (Math.round(bikePosition.speed * (bikePosition.time > timeLimit ? 3.6 : 1) * 100) / 100) + " km/h" : "") + ((bikePosition.heading != null) ? "<br \>Max speed exceeded: " + (bikePosition.maxSpeedExceeded ? "yes" : "no") + "<br \>Heading: " + bikePosition.heading + "°" : "") + "<br \>Battery voltage: " + bikePosition.batteryVoltage + " V<br \>Battery low: " + (bikePosition.batteryLow ? "yes" : "no") + "<br \><a href=\"javascript:generateGpx(&quot;" + bike.id + "&quot;)\">Download GPX track</a>";

    }

    function updateLastPath(bike) {
        addHistoryPosition(bike, bike.currentPosition);
        map.appendToLine(bike.currentPosition.lat, bike.currentPosition.lng);
    }

    function removeLastPath() {
        lastPathPositionMarkerIds.forEach(function (i) {
            map.removeMarker(i);
        });
        lastPathPositionMarkerIds = [];
        map.removeLine();

        //currentPositionMarkerIds.forEach(id => {
            //map.updateMarkerIcon(id, MarkerStyles.currentLive.icon);
        //});
    }

    var onBikeClick = null;

    /**
     * Register function that will be called on bike's current position mouse click
     */
    function setOnBikeClick(callback) {
        onBikeClick = callback;
    }

    /**
     * Register function that will be called on bike's current position mouse click
     */
    function setLast(value) {
        last = value;
    }

    var outsideAllowedAreaCallback = function() {

    };

    function registerOutsideAllowedAreaCallback(callback) {
        outsideAllowedAreaCallback = callback;
    }

    function calculateOutsideAllowedArea() {
        var count = 0;
        Object.keys(bikes).forEach(key => {
          if (bikes[key].currentPosition !== null && bikes[key].currentPosition.outsideAllowedArea) count = count + 1;
        });
        return count;
    }

    var stationaryOutsideStationCallback = function() {

    };

    function registerStationaryOutsideStationCallback(callback) {
        stationaryOutsideStationCallback = callback;
    }

    function calculateStationaryOutsideStation() {
        var count = 0;
        Object.keys(bikes).forEach(key => {
            if (bikes[key].currentPosition !== null && bikes[key].currentPosition.stationaryOutsideStation) count = count + 1;
        });
        return count;
    }

    var batteryLowCallback = function() {

    };

    function registerBatteryLowCallback(callback) {
        batteryLowCallback = callback;
    }

    function calculateBatteryLow() {
        var count = 0;
        Object.keys(bikes).forEach(key => {
            if (bikes[key].currentPosition !== null && bikes[key].currentPosition.batteryLow) count = count + 1;
        });
        return count;
    }

    var maxSpeedExceededCallback = function() {

    };

    function registerMaxSpeedExceededCallback(callback) {
        maxSpeedExceededCallback = callback;
    }

    function calculateMaxSpeedExceeded() {
        var count = 0;
        Object.keys(bikes).forEach(key => {
            if (bikes[key].currentPosition !== null && bikes[key].currentPosition.maxSpeedExceeded) count = count + 1;
        });
        return count;
    }

    function filterOutsideAllowedAreaBikes() {
        removeLastPath();
        Object.keys(bikes).forEach(key => {
            if (bikes[key].currentPosition !== null && bikes[key].currentPosition.outsideAllowedArea) {
                map.showMarker(bikes[key].id);
            } else {
                map.hideMarker(bikes[key].id);
            }
        });
    }

    function filterStationaryOutsideStationBikes() {
        removeLastPath();
        Object.keys(bikes).forEach(key => {
            if (bikes[key].currentPosition !== null && bikes[key].currentPosition.stationaryOutsideStation) {
                map.showMarker(bikes[key].id);
            } else {
                map.hideMarker(bikes[key].id);
            }
        });
    }

    function filterBatteryLowBikes() {
        removeLastPath();
        Object.keys(bikes).forEach(key => {
            if (bikes[key].currentPosition !== null && bikes[key].currentPosition.batteryLow) {
                map.showMarker(bikes[key].id);
            } else {
                map.hideMarker(bikes[key].id);
            }
        });
    }

    function filterMaxSpeedExceededBikes() {
        removeLastPath();
        Object.keys(bikes).forEach(key => {
            if (bikes[key].currentPosition !== null && bikes[key].currentPosition.maxSpeedExceeded) {
               map.showMarker(bikes[key].id);
            } else {
                map.hideMarker(bikes[key].id);
            }
        });
    }

    function filterAllBikes() {
        removeLastPath();
        Object.keys(bikes).forEach(key => {
            map.showMarker(bikes[key].id);
        });
    }

    return {
        init: init,
        getBikes: bikes,
        getBikesByName: bikesByName,
        updateBikeName: updateBikeName,
        getBikePositions: bikePositions,
        showLastPath: addLastPath,
        highlightBike: highlightBike,
        unHighlightBike: unHighlightBike,
        setOnBikeClick: setOnBikeClick,
        setLast: setLast,
        registerOutsideAllowedAreaCallback: registerOutsideAllowedAreaCallback,
        registerStationaryOutsideStationCallback: registerStationaryOutsideStationCallback,
        registerBatteryLowCallback: registerBatteryLowCallback,
        registerMaxSpeedExceededCallback: registerMaxSpeedExceededCallback,
        filterOutsideAllowedAreaBikes: filterOutsideAllowedAreaBikes,
        filterStationaryOutsideStationBikes: filterStationaryOutsideStationBikes,
        filterBatteryLowBikes: filterBatteryLowBikes,
        filterMaxSpeedExceededBikes: filterMaxSpeedExceededBikes,
        filterAllBikes: filterAllBikes
    }
}