export default function(map) {
  const markers = {};
  const descriptions = {};
  const onClickCallbacks = {};
  var polyline = null;
  const center = map.getCenter();
  const zoom = map.getZoom();

  var selectedMarker = null;
  var clickedMarker = null;

  const hoverInfoWindow = new window.google.maps.InfoWindow({content: ""});
  const clickInfoWindow = new window.google.maps.InfoWindow({content: ""});

  window.google.maps.event.addListener(hoverInfoWindow, "domready", function(event){
    // ṬODO: Remove close button from hover info window
    // http://stackoverflow.com/a/19893435/325852
    // $("#viewObject").parent().parent().next().remove();
  });

  /**
   * Add new marker to the map
   * @param {Number|String} id         Bike, station ID, history position ID
   * @param {Number} lat               Latitude
   * @param {Number} lng               Longitude
   * @param {String} title             Marker title
   * @param {String} description       Marker description
   * @param {Object} style             Marker styles
   * @param {Function} onClickCallback Executed when the marker was clicked
   */
  function addMarker(id, lat, lng, title, description, style, zIndex, onClickCallback) {
    const marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(lat, lng),
      draggable: false,
      zIndex: zIndex,
      icon: style.icon || null
    });

    markers[id] = marker;
    descriptions[id] = description;
    onClickCallbacks[id] = onClickCallback;

    window.google.maps.event.addListener(marker, "mouseover", function() {
      //if(clickedMarker == null) {
        selectedMarker = marker;
        clickedMarker = null;
        clickInfoWindow.setContent(descriptions[id]);
        clickInfoWindow.open(map, this);
      //}
    });

    window.google.maps.event.addListener(marker, "mouseout", function() {
      if(clickedMarker == null) {
        selectedMarker = null;
        clickInfoWindow.close();
      }
    });

    window.google.maps.event.addListener(marker, "click", function() {
      selectedMarker = marker;
      clickedMarker = marker;
      clickInfoWindow.setContent(descriptions[id]);
      clickInfoWindow.open(map, this);

      window.google.maps.event.addListener(clickInfoWindow,'closeclick',function() {
        clickedMarker = null;
        selectedMarker = null;
      });

     // TODO: debug open info window when marker was clicked
     // hoverInfoWindow.close();
     // clickInfoWindow.close();
     // clickInfoWindow.setContent(descriptions[id]);
     // clickInfoWindow.open(map, this);

        //clickInfoWindow.close();
        //clickInfoWindow.setContent(descriptions[id]);
        //clickInfoWindow.open(map, this);

      if (typeof onClickCallbacks[id] === "function") {
         onClickCallbacks[id](id);
      }
    });

    // Show info window when user hovers ofer marker
    /*google.maps.event.addListener(marker, "mouseover", function() {
      hoverInfoWindow.close();
      hoverInfoWindow.setContent(descriptions[id]);
      hoverInfoWindow.open(map, this);
    });

      // Show info window when user hovers ofer marker
      google.maps.event.addListener(marker, "touchstart", function() {
          hoverInfoWindow.close();
          hoverInfoWindow.setContent(descriptions[id]);
          hoverInfoWindow.open(map, this);
      });*/

    // Hide info window when user ends hoving ofer marker
    /*google.maps.event.addListener(marker, "mouseout", function() {
      hoverInfoWindow.close();
    });*/

    // Add marker to the map
    marker.setMap(map);
  }

  /**
   * Update markers position and description
   * @param {Number|String} id   Bike, station ID, history position ID
   * @param {Number} lat         Latitude
   * @param {Number} lng         Longitude
   * @param {String} description Marker description
   */
  function updateMarker(id, lat, lng, description) {
    const marker = getMarkerById(id);
    descriptions[id] = description;
    if(marker === selectedMarker) clickInfoWindow.setContent(descriptions[id]);
    marker.setPosition(new window.google.maps.LatLng(lat, lng));
    return marker;
  }

  function updateMarkerIcon(id, icon) {
    const marker = getMarkerById(id);
    marker.setIcon(icon);
    return marker;
  }

  /**
   * Get marker by ID
   * @param  {Number|String} id Bike, station ID, history position ID
   * @return {Object}           Marker object
   */
  function getMarkerById(id) {
    return markers[id];
  }

  /**
   * Remove marker from the map
   * @param  {Number|String} id Bike, station ID, history position ID
   */
  function removeMarker(id) {
    const marker = markers[id];

    if (marker) {
      // Remove all event listeners first to avoid memory leaks
      // Resource: https://developers.google.com/maps/documentation/javascript/events#removing
      window.google.maps.event.clearInstanceListeners(marker);
      marker.setMap(null);

      // Remove marker and data associated with it
      delete markers[id];
      delete descriptions[id];
      delete onClickCallbacks[id];
    }
  }

  /**
   * Remove all markers from the map
   */
  function removeAllMarkers() {
    for (var id in markers) {
      removeMarker(id);
    }
  }

  /**
   * Add line representing the bike route/path to the map
   * @param {Array}  coordinates Line coordinates
   * @param {Number} weight      Line thickness
   * @param {String} color       Line color
   * @param {Number} opacity     Line opacity
   */
  function addLine(coordinates, weight, color, opacity) {
    polyline = new window.google.maps.Polyline({
      path: coordinates,
      geodesic: true,
      strokeColor: color,
      strokeWeight: weight,
      strokeOpacity: opacity,
      // icons: [{
      //   icon: {
      //     path: google.maps.SymbolPath.FORWARD_OPEN_ARROW
      //   },
      //   // offset: '100%'
      //   offset: '0',
      //   repeat: '40px'
      // }],
    });

    // Add line to the map
    polyline.setMap(map);
  }

  /**
   * Remove line representing the bike route/path from the map
   */
  function removeLine() {
    if(polyline != null) polyline.setMap(null);
    polyline = null;
  }

  /**
   * Update line with the new bike position
   * @param {Number} lat Latitude
   * @param {Number} lng Longitude
   */
  function appendToLine(lat, lng) {
    const path = polyline.getPath();

    // Because path is an MVCArray, we can simply append a new coordinate
    // and it will automatically appear.
    path.push(new window.google.maps.LatLng(lat, lng));
  }

  /**
   * Update line with the new bike position
   * @param {Number} lat Latitude
   * @param {Number} lng Longitude
   */
  function prependToLine(lat, lng) {
    const path = polyline.getPath();

    // Because path is an MVCArray, we can simply prepend a new coordinate
    // and it will automatically appear.
    path.unshift(new window.google.maps.LatLng(lat, lng));
  }

  /**
   * Focus map on specific marker and zoom level
   * @param  {Object} marker Marker instance
   * @param  {Number} zoom   Zoom level
   */
  function flyToMarker(marker, zoom) {
    map.setZoom(zoom);
    map.setCenter(marker.getPosition());
  }

  /**
   * Focus map on specific marker and zoom level
   * @param  {Object} marker Marker instance
   * @param  {Number} zoom   Zoom level
   */
  function flyToLine(zoom) {
    const bounds = new window.google.maps.LatLngBounds();
    polyline.getPath().forEach(function (p) {
    bounds.extend(p); });

    map.setZoom(zoom);
    map.setCenter(bounds.getCenter());
  }


  /**
   * Display contour shape on map based on the coordinates array
   * @param {Array} coordinates Array of items with latitude and longitude
   *                            Example item: {lat: ..., lng: ...}
   */
  function addShape(coordinates) {
    new window.google.maps.Polygon({
      map: map,
      paths: coordinates,
      strokeColor: "#e56a5d",
      strokeOpacity: 0.5,
      strokeWeight: 10,
      // fillColor: "#652F29",
      fillOpacity: 0,
      draggable: false,
      geodesic: true
    });
  }

  function hideMarker(id) {
    const marker = markers[id];
    if(marker !== undefined && marker !== null) {
      marker.setVisible(false);
    }
  }

  function showMarker(id) {
    const marker = markers[id];
    if(marker !== undefined && marker !== null) {
      marker.setVisible(true);
    }
  }

  return {
    addMarker: addMarker,
    updateMarker: updateMarker,
    updateMarkerIcon: updateMarkerIcon,
    removeMarker: removeMarker,
    removeAllMarkers: removeAllMarkers,
    getMarkerById: getMarkerById,

    addLine: addLine,
    prependToLine: prependToLine,
    appendToLine: appendToLine,
    removeLine: removeLine,

    addShape: addShape,

    flyToMarker: flyToMarker,
    flyToLine: flyToLine,

    recenter: function() {
      map.setCenter(center);
      map.setZoom(zoom);
    },

    hideMarker: hideMarker,
    showMarker: showMarker
  };
};
